/**
 * TRACKING GTM
 * @author alexandre Flatrès
 * par artwaï pour Groupe Bâtisseurs d'avenir
 *
 * JS sur le tracking GTM
 */
(function () {
	// Tracking sur toutes les pages
	window.onload = function () {
		dataTracking.env_channel = window.getComputedStyle(
			document.querySelector('html')
		)['z-index'];
		if (dataTracking.env_channel != '') {
			if (dataTracking.env_channel == '1') {
				dataTracking.env_channel = 'mobile';
			} else if (dataTracking.env_channel == '2') {
				dataTracking.env_channel = 'tablet';
			} else if (dataTracking.env_channel == '3') {
				dataTracking.env_channel = 'desktop';
			} else if (dataTracking.env_channel == '4') {
				dataTracking.env_channel = 'wide';
			}
		}

		window.dataLayer.push({
			event: 'datalayer-loaded',
			page_type: dataTracking.pageType,
			env_channel: dataTracking.env_channel,
			page_cat: dataTracking.page_cat,
			program_id: dataTracking.program_id,
			promotion_name: dataTracking.promotion_name,
			program_category: dataTracking.program_category,
			program_price_from: dataTracking.program_price_from,
			program_type: dataTracking.program_type,
			program_eligibility: dataTracking.program_eligibility,
			program_localisation: dataTracking.program_localisation,
			'connected-user': dataTracking.is_connected ? '1' : '0',
		});
	};

	// Tracking à chaque validation d'un formulaire WPCF7
	document.addEventListener(
		'wpcf7mailsent',
		function (event) {
			// GET id_source Unlatch from contact form
			var formId = event.detail.contactFormId;
			var currentForm = dataTracking.api_leads.find(
				(o) => o.formulaire === formId
			);
			if (currentForm !== undefined) {
				formId =
					currentForm[dataTracking.current_structure.id_source_reference];
			}

			// GET email from inputs array with name your-email
			var contactEmail = event.detail.inputs.find(
				(o) => o.name === 'your-email'
			).value;
			if (contactEmail == '') {
				contactEmail = 'Email non trouvé';
			}

			var formDestinataire = event.detail.inputs.find((o) => o.name === 'to');
			if (formDestinataire == undefined) {
				formDestinataire = '';
			} else {
				formDestinataire = formDestinataire.value;
			}

			// Si on est sur un formulaire qui n'est pas une Offre d'emploi ET un Plan ET un document à télécharger
			if (
				event.detail.contactFormId != dataTracking.contact_plan_id &&
				event.detail.contactFormId != dataTracking.contact_document_id &&
				event.detail.contactFormId != dataTracking.contact_offer_id
			) {
				var contactFormTitle = event.target.querySelector(
					'.description-form strong'
				).innerText;
				if (contactFormTitle == '') {
					contactFormTitle = 'Titre du formulaire non trouvé';
				}

				// Si on est sur un programme ou un terrain
				if (
					dataTracking.pageType == 'program' ||
					dataTracking.pageType == 'land'
				) {
					var lotReference = event.target.querySelector(
						'input[name*="lot-reference"]'
					).value;
					var datasArr = {
						event: '',
						form_id: formId,
						contact_type: contactFormTitle,
						user_mail: contactEmail,
						destinataire: formDestinataire,
						contact_currency: 'EUR',
						product: [
							{
								program_name: dataTracking.promotion_name,
								apartment_quantity: '1',
								program_localisation: dataTracking.program_localisation,
							},
						],
					};

					if (lotReference != '') {
						// Si on est sur une demande de contact LOT
						var lotPrice = event.target.querySelector(
							'input[name*="lot-price"]'
						).value;
						datasArr.event = 'apartment-contact-form-success';
						datasArr.contact_price_from = lotPrice;
						datasArr.product[0].apartment_ref = event.target.querySelector(
							'input[name*="lot-id"]'
						).value;
						datasArr.product[0].apartment_name =
							lotReference != '' ? lotReference.split(': ')[1] : '';
						datasArr.product[0].apartment_floor = event.target.querySelector(
							'input[name*="lot-floor"]'
						).value;
						datasArr.product[0].apartment_price_from = lotPrice;
						datasArr.product[0].apartment_exposition =
							event.target.querySelector('input[name*="lot-exposition"]').value;
					} else {
						// Si on est sur une demande de contact OPERATION
						datasArr.event = 'program-contact-form-success';
						datasArr.contact_price_from = dataTracking.program_price_from;
						datasArr.product[0].program_id = dataTracking.program_id;
						datasArr.product[0].program_type = dataTracking.program_type;
						datasArr.product[0].program_price_from =
							dataTracking.program_price_from;
						datasArr.product[0].program_eligibility =
							dataTracking.program_eligibility;
					}

					window.dataLayer.push(datasArr);
				} else {
					window.dataLayer.push({
						event: 'contact_form_success',
						form_id: formId,
						contact_type: contactFormTitle,
						user_mail: contactEmail,
						destinataire: formDestinataire,
					});
				}
			}

			// Si on est sur un formulaire de téléchargement de document
			if (event.detail.contactFormId == dataTracking.contact_document_id) {
				let docname = event.target.querySelector(
					'input[name*="your-document"]'
				).value;
				let doctype = event.target
					.querySelector('.description-form strong')
					.innerText.trim()
					.toLowerCase();
				doctype = doctype.replace(' par email', '');
				doctype = doctype.replace('télécharger le ', '');
				doctype = doctype.replace('télécharger la ', '');

				window.dataLayer.push({
					event: 'doc_download',
					form_id: formId,
					doc_name: docname,
					doc_type: doctype,
					program_name: dataTracking.promotion_name,
					user_mail: contactEmail,
					destinataire: formDestinataire,
				});
			} else if (event.detail.contactFormId == dataTracking.contact_plan_id) {
				// Si on est sur un formulaire de téléchargement de document
				let docname = event.target.querySelector(
					'input[name*="your-plan"]'
				).value;
				let doctype = 'plan';

				window.dataLayer.push({
					event: 'plan_download',
					form_id: formId,
					doc_name: docname,
					doc_type: doctype,
					program_name: dataTracking.promotion_name,
					user_mail: contactEmail,
					destinataire: formDestinataire,
				});
			}

			// Si on est sur un formulaire d'offre d'emploi
			if (event.detail.contactFormId == dataTracking.contact_offer_id) {
				window.dataLayer.push({
					event: 'job-form-success',
					form_id: formId,
				});
			}
		},
		false
	);

	// Tracking a chaque recherche simple
	document.addEventListener('click', function (event) {
		const search_button = document.querySelector(
			'form.searchform--main .searchform__button'
		);

		if (search_button == null || typeof search_button == 'undefined') {
			return;
		}

		if (
			event.target == search_button ||
			event.target.closest('.searchform--main .searchform__button') ==
				search_button
		) {
			let form = search_button.closest('form');
			let typology = form.querySelector('select[name="typology[]"]');
			let typologyValues = [];
			if (typology != null && typeof typology != 'undefined') {
				for (let i = 0; i < typology.options.length; i++) {
					if (typology.options[i].selected) {
						typologyValues.push(typology.options[i].value);
					}
				}
			}

			if (typologyValues.length == 0) {
				typologyValues = 'Non renseigné';
			}

			let isInvest = form.querySelector('input[name="is_invest"]');
			let isInvestValue = 'Non renseigné';
			if (isInvest.value != '') {
				isInvestValue = isInvest.value == '1' ? 'Investir' : 'Habiter';
			}

			let price_max = form.querySelector('input[name="price_max"]');
			let fourchette_prix_max = definePriceRange(price_max.value);

			let zone = form.querySelector('input[name="zone"]').value;
			let zoneID = form.querySelector('input[name="zone_id"]');
			let zoneType = form.querySelector('input[name="zone_type"]');
			if ((zoneID.value == '' || zoneType.value == '') && zone != '') {
				return;
			}			
			if (zone == '' || zone == 'Dernière recherche') {
				zone = 'Toutes';
			}

			let features = {
				type_achat: isInvestValue,
				type_bien: typologyValues,
				zone_geographique: zone,
				prix_max: fourchette_prix_max,
			};

			if (document.body.classList.contains('lotisseur')) {
				delete features.type_bien;
				features.surface_min = defineSurfaceRange(form.querySelector('input[name="surface_min"]').value, 'lotisseur');
			}

			window.dataLayer.push({
				event: 'recherche',
				features: features,
			});
		}
	});

	// Tracking à chaque recherche avancée
	document.addEventListener('click', function (event) {
		const search_button = document.querySelector(
			'.searchform--expand .searchform__button'
		);

		if (search_button == null || typeof search_button == 'undefined') {
			return;
		}

		if (
			event.target == search_button ||
			event.target.closest('.searchform--expand .searchform__button') ==
				search_button
		) {
			let form = search_button.closest('form');
			let features = {};
			let batiType = 'promoteur';

			if (document.body.classList.contains('lotisseur')) {
				batiType = 'lotisseur';
				// libre_de_constructeur
				let isFreeManufacturer = form.querySelectorAll(
					'input[name="free_manufacturer"]'
				);
				let isFreeManufacturerValue = 'Non renseigné';
				isFreeManufacturer.forEach((input) => {
					if (input.checked) {
						isFreeManufacturerValue = input.value == '1' ? 'Oui' : 'Non';
					}
				});

				// terrain_viabilise
				let isViabilise = form.querySelectorAll('input[name="viabilise"]');
				let isViabiliseValue = 'Non renseigné';
				isViabilise.forEach((input) => {
					if (input.checked) {
						isViabiliseValue = input.value == '1' ? 'Oui' : 'Non';
					}
				});

				let rayon = form.querySelectorAll('input[name="radius"]');
				let rayonValue = 'Non renseigné';
				rayon.forEach((input) => {
					if (input.checked) {
						rayonValue = input.value + ' km';
					}
				});

				features.rayon = rayonValue;
				features.libre_de_constructeur = isFreeManufacturerValue;
				features.terrain_viabilise = isViabiliseValue;
				features.offre_terrain_maison = 'Non renseigné';
			

			} else {
				let pieces = form.querySelectorAll('input[name="piece_number[]"]');
				let piecesValues = [];
				//as typologies are cheboxes, we need to loop through all options
				pieces.forEach((piece) => {
					if (piece.checked) {
						piecesValues.push(piece.value);
					}
				});

				if (piecesValues.length == 0) {
					piecesValues = 'Non renseigné';
				}

				let fiscals = form.querySelectorAll('input[name="fiscal[]"]');
				let fiscalsValues = [];
				//as typologies are cheboxes, we need to loop through all options
				fiscals.forEach((fiscal) => {
					if (fiscal.checked) {
						fiscalsValues.push(defineFiscalLabel(fiscal.value));
					}
				});

				if (fiscalsValues.length == 0) {
					fiscalsValues = 'Non renseigné';
				}

				let typologies = form.querySelectorAll('input[name="typology[]"]');
				let typologyValues = [];
				//as typologies are cheboxes, we need to loop through all options
				typologies.forEach((typology) => {
					if (typology.checked) {
						typologyValues.push(typology.value);
					}
				});

				if (typologyValues.length == 0) {
					typologyValues = 'Non renseigné';
				}

				features.type_bien = typologyValues;
				features.nombre_pieces = piecesValues;
				features.dispositif_fiscaux = fiscalsValues;
				
			}

			let isInvest = form.querySelector('input[name="is_invest"]');
			let isInvestValue = isInvest.checked ? 'Investir' : 'Habiter';

			let price_min = form.querySelector('input[name="price_min"]');
			let fourchette_prix_min = definePriceRange(price_min.value, batiType);

			let price_max = form.querySelector('input[name="price_max"]');
			let fourchette_prix_max = definePriceRange(price_max.value, batiType);

			let surface_min = form.querySelector('input[name="surface_min"]');
			let fourchette_surface_min = defineSurfaceRange(
				surface_min.value,
				batiType
			);

			let surface_max = form.querySelector('input[name="surface_max"]');
			let fourchette_surface_max = defineSurfaceRange(
				surface_max.value,
				batiType
			);

			let zone = form.querySelector('input[name="zone"]').value;
			let zoneID = form.querySelector('input[name="zone_id"]');
			let zoneType = form.querySelector('input[name="zone_type"]');
			if ((zoneID.value == '' || zoneType.value == '') && zone != '') {
				return;
			}
			if (zone == '' || zone == 'Dernière recherche') {
				zone = 'Toutes';
			}


			features.zone_geographique = zone;
			features.type_achat = isInvestValue;
			features.prix_min = fourchette_prix_min;
			features.prix_max = fourchette_prix_max;
			features.surface_min = fourchette_surface_min;
			features.surface_max = fourchette_surface_max;

			window.dataLayer.push({
				event: 'recherche_avancee',
				features: features,
			});
		}
	});

	function definePriceRange(price, type) {
		if (type == 'lotisseur') {
			switch (true) {
				case price == '':
					return 'Non renseigné';
				case price < 50000:
					return 'Moins de 50 000€';
				case price < 70000:
					return '50 000€ à 70 000€';
				case price < 90000:
					return '70 000€ à 90 000€';
				case price < 120000:
					return '90 000€ à 120 000€';
				case price < 150000:
					return '120 000€ à 150 000€';
				case price < 200000:
					return '150 000€ à 200 000€';
				case price < 250000:
					return '200 000€ à 250 000€';
				default:
					return '250 000€ et plus';
			}
		}

		switch (true) {
			case price == '':
				return 'Non renseigné';
			case price < 100000:
				return 'Moins de 100 000€';
			case price < 150000:
				return '100 000€ à 150 000€';
			case price < 200000:
				return '150 000€ à 200 000€';
			case price < 250000:
				return '200 000€ à 250 000€';
			case price < 300000:
				return '250 000€ à 300 000€';
			case price < 350000:
				return '300 000€ à 350 000€';
			case price < 400000:
				return '350 000€ à 400 000€';
			case price < 450000:
				return '400 000€ à 450 000€';
			case price < 500000:
				return '450 000€ à 500 000€';
			default:
				return '500 000€ et plus';
		}
	}

	function defineSurfaceRange(surface, type) {
		if (type == 'lotisseur') {
			switch (true) {
				case surface == '':
					return 'Non renseigné';
				case surface < 300:
					return 'Moins de 300m²';
				case surface < 400:
					return '300m² à 400m²';
				case surface < 500:
					return '400m² à 500m²';
				default:
					return 'Plus de 500m²';
			}
		}
		switch (true) {
			case surface == '':
				return 'Non renseigné';
			case surface < 20:
				return 'Moins de 20m²';
			case surface < 35:
				return '20m² à 35m²';
			case surface < 50:
				return '35m² à 50m²';
			case surface < 65:
				return '50m² à 65m²';
			case surface < 80:
				return '65m² à 80m²';
			case surface < 95:
				return '80m² à 95m²';
			default:
				return '95m² et plus';
		}
	}

	function defineFiscalLabel(fiscal) {
		switch (fiscal) {
			case 'taux_zero':
				return 'Prêt à taux zéro';
			case 'TVA-5-5':
				return 'TVA réduite 5,5%';
			case 'Primo-Accedant':
				return 'Primo accédant';
			case 'ANRU':
				return 'Zone ANRU';
			case 'Accession-maitrisé':
				return 'Accession maitrisée';
			case 'Loi Pinel':
				return 'Pinel';
			case 'pinel +':
				return 'Pinel plus';
			case 'lmnp':
				return 'LMP/LMNP';
			case 'Nue-propriété':
				return 'Nue-propriété';
			default:
				return fiscal;
		}
	}

	// Tracking à chaque click sur le bouton retour d'une page single programme non terminé
	document.addEventListener(
		'click',
		function (event) {
			const yoast_previous_button = document.querySelector('#breadcrumbs a');
			if (
				yoast_previous_button &&
				yoast_previous_button.textContent === '< Retour'
			) {
				// To do : Mettre à jour l'event
				// window.dataLayer.push({
				//     "event": "breadcrumbs-previous",
				// });
			}
		},
		false
	);

	// Tracking à chaque validation d'une création de compte + newsletter-success
	// -> Voir ajax.js
})();
